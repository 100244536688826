const axios = require('axios').default;

module.exports = {
   async search(type, value){
      try {
         if(type == "id"){
            let data = {"id": value}
            let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/client/find/id`, data) 
            return apiResponse
         }else if(type == "name"){
            let data = {"name": value}
            let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/client/find/name`, data) 
            return apiResponse
         }
       } catch (error) {
         return error
      }
   },
async findID(clientId){
   let data = {"id":clientId}
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/client/find/id/`, data) 
      return apiResponse
    } catch (e) {
      return e
   }
},
async dataClients(){
   try {
      const apiResponse = await axios.get(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/categorias`) 
      return apiResponse
    } catch (e) {
      return e
   }
},

async dataSubcategorias(){
   try {
      const apiResponse = await axios.get(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/subcategorias`) 
      return apiResponse
    } catch (e) {
      return e
   }
},

async dataEmpresas(){
   try {
      const apiResponse = await axios.get(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/empresas`) 
      return apiResponse
    } catch (e) {
      return e
   }
},
async dataDescuentos(){
   try {
      const apiResponse = await axios.get(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/descuentos`) 
      return apiResponse
    } catch (e) {
      return e
   }
},

async crearSubcategoria(data){
   try {
      console.log(data)
      let apiResponse = await axios.post(`http://127.0.0.1:8000/subcategorias/nuevo`, data) 
      return apiResponse
    } catch (error) {
      return error
   }
},

async deleteClient(data){
   try {
      let apiResponse = await axios.delete(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/client/delete`, {
         headers: {},
         data: data,
       }) 
      return apiResponse
    } catch (error) {
      return error
   }
},
async dataClientsPaginated(page){
   try {
      const apiResponse = await axios.get(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/client/list/paginate?page=${page}`) 
      return apiResponse
    } catch (e) {
      return e
   }
},
async create(data){
   try {
      console.log(data)
      let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/categorias/nuevo`, data) 
      return apiResponse
    } catch (error) {
      return error
   }
},
async createUser(data){
   try {
      let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/client/user/create`, data) 
      return apiResponse
    } catch (error) {
      return error
   }
},
async update(data){
   try {
      let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/categorias/nuevo`, data) 
      return apiResponse
    } catch (error) {
      return error
   }
},
}