<style scoped>
.button-client-form{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #f9faff !important;
    background-color: #247ba0 !important;
    font-weight: bold !important;;
    border-radius: 30px !important;
    border: 2px solid #247ba0;
}
.button-client-form:hover{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #f9faff !important;
    background-color: #011c27 !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    border: 2px solid #f9faff;
}
  .button-view-client{
    border: 2px solid #595959;
    position: relative;
    background-color:transparent !important;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    color: #011c27 !important;
    font-family: "Inter", sans-serif !important;
    font-weight:400 !important;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
  }
  .button-view-client:hover{
    border: 2px solid #011c27;
    position: relative;
    background-color:#011c27 !important;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    color: white !important;
    font-family: "Inter", sans-serif !important;
    font-weight:400 !important;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
  }
  .button-new-client{
    border: 2px solid #247ba0;
    margin-right:30px; 
    background-color:#247ba0 !important;
    border-radius: 10px;
    color: white;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .button-new-client:hover{
    border: 2px solid #011c27;
    margin-right:30px; 
    background-color: #ffffff !important;
    border-radius: 10px;
    color: #595959 !important;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .btn-group, .btn-group-vertical {
    margin-right: 15px;
    border: 2px solid #595959;
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    color: #011c27;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
}
.btn-group > .btn, .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto;
    font-weight: 500;
    }
.tbody-table-clients{
  font-family: "Inter", sans-serif;
  color: #011c27;
  font-weight: 500;
}
table th, .table td {
    vertical-align: middle;
}
.id-box-client{
    font-weight: 600;
    background-color: #011c27;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
}
.brand-box-client{
    font-weight: 600;
    background-color: #247ba0;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
}
.table th, .table td {
    border-bottom: 2px solid #d3d3d3;
}
.table thead th {
    border-bottom: 1px solid #e9e9e9;
    border-top: 1px solid #ffffff;
}
table th, .table td {
    border-top: 1px solid #ffffff;
}
.div-table-clients{
  padding-left: 24px;
  padding-right: 24px;
}
.tr-table-clients{
  font-family: nunito;
  color: #595959;
  font-weight: 600;
}
.card-clients-body{
    background-color: #f9faff;
    border-radius: 30px;
}
.card-clients-body{
    padding: 40px !important;
}

#table-clients > div{
    cursor: pointer;
    background-color: #f9faff;
}
.title-clients {
  font-size: 40px;
  margin: 0 0 0px 0;
  color: #011c27;
  margin-bottom: 30px;
  padding-left:30px;
  font-family: nunito;
  font-weight: 600;
}
.dot-clients{
  color:#247ba0;
  font-family: nunito;
  font-weight: 600;

}
</style>
<script>
import Layout from "../../layouts/main";
const clientController = require("@/controllers/endpoint/clients");
import Swal from "sweetalert2";
const userController = require("@/controllers/endpoint/user");
import axios from "axios";

/**
 * Checkout Component
 */
export default {
  components: {
    Layout,
  },
  data() {
    return {
      categorias: [], // Array para almacenar las categorías
      subcategorias: [],
      subcategorias_list: [], // Array para almacenar todas las subcategorías
      selectedCategoria: null, // Variable para almacenar el ID de la categoría seleccionada
      selectedSubcategoria: null, // Variable para almacenar el ID de la subcategoría seleccionada
      filteredSubcategorias: [],
      imagenURL: '', 
      imagenCargada: false,
      options_search:[
          { value: 'id', text: 'ID' },
          { value: 'name', text: 'Name' },
        ],
      url_endpoint: process.env.VUE_APP_BASEPATH_ENDPOINT,
      selected_search:'id',
      searchInput:'',
      sort: "ASC",
      user: {},
      spinner: true,
      clients: {},
      brands: {},
      title: "Clients",
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 20, 50],
      filter: null,
      sortBy: "id",
      filterOn: [],
      sortDesc: false,
      fields: [
        { key: "name", sortable: false },
        { key: "description", sortable: false },
        { key: "No. Brands", sortable: false },
        { key: "createdAt", sortable: false, label: "Date" },
        { key: "id", sortable: false, label: "Action" },
      ],
      fields2: [
        { key: "name", sortable: false },
        { key: "description", sortable: false },
        { key: "No. Brands", sortable: false },
        { key: "createdAt", sortable: false, label: "Desde" },
      ],
      nueva_categoria: {
        nombre: "",
        icono: "",
        facebook: "",
        instagram: "",
        website: "",
      },
      newCLient: {

      },
      editar_empresa: {
        nombre: "",
      },
      editCLient: {
        id: "",
        nombre: "",
        description: "",
        rol: "",
      },
    };
  },
  computed: {
 
  },

  mounted() {
    this.userData();
    this.getClients();
    this.loadCategorias();
    this.Subcategorias_list();
  },
  methods: {
    editarDatos() {
  const formData = new FormData();
  console.log(this.editar_empresa)
      formData.append('nombre', this.editar_empresa.nombre.toUpperCase());
      formData.append('facebook', this.editar_empresa.facebook);
      formData.append('instagram', this.editar_empresa.instagram);
      formData.append('website', this.editar_empresa.website);
      formData.append('direccion', this.editar_empresa.direccion.toUpperCase());
      formData.append('telefono', this.editar_empresa.telefono);
      formData.append('id_categoria', this.editar_empresa.selectedCategoria);
      formData.append('id_subcategoria', this.editar_empresa.selectedSubcategoria);

  // Verifica si se ha seleccionado un nuevo icono
  if (this.$refs.icono.files[0]) {
      formData.append('nombre', this.editar_empresa.nombre.toUpperCase());
      formData.append('facebook', this.editar_empresa.facebook);
      formData.append('instagram', this.editar_empresa.instagram);
      formData.append('website', this.editar_empresa.website);
      formData.append('direccion', this.editar_empresa.direccion.toUpperCase());
      formData.append('telefono', this.editar_empresa.telefono);
      formData.append('icono', this.$refs.icono.files[0]);
      formData.append('id_categoria', this.editar_empresa.selectedCategoria);
      formData.append('id_subcategoria', this.editar_empresa.selectedSubcategoria);
  }

  // Verifica si se ha seleccionado un nuevo icono antes de enviar los datos al servidor
  if (!formData.has('icono')) {
    alert('No se ha seleccionado un nuevo icono. Los datos se actualizarán sin cambios en el icono.');
  }

  axios.post(`https://membresiaintegra.com/php_api/empresas/${this.editar_empresa.id}/actualizar`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(response => {
    console.log('Respuesta del servidor:', response.data);
    alert('Datos actualizados con éxito.');
    this.closeModal();
    this.spinner = true;
    this.getClients();
  })
  .catch(error => {
    console.error('Error al actualizar los datos:', error);
    alert('Ocurrió un error al actualizar los datos. Por favor, inténtelo de nuevo.');
  });
},
    getCategoriaNombre(id_categoria) {
      const categoria = this.categorias.find(cat => cat.id === parseInt(id_categoria));
      return categoria ? categoria.nombre : 'Desconocido';
    },
    getSubcategoriaNombre(id_subcategoria) {
      const subcategoria = this.subcategorias_list.find(subcat => subcat.id === parseInt(id_subcategoria));
      return subcategoria ? subcategoria.nombre : '';
    },
    loadCategorias() {
      axios.get('https://membresiaintegra.com/php_api/categorias')
        .then(response => {
          // Guarda las categorías en el array categorias
          this.categorias = response.data;
        })
        .catch(error => {
          console.error('Error al cargar categorías:', error);
        });
    },
    Subcategorias_list() {
      axios.get('https://membresiaintegra.com/php_api/subcategorias')
        .then(response => {
          // Guarda las categorías en el array categorias
          this.subcategorias_list = response.data;
        })
        .catch(error => {
          console.error('Error al cargar categorías:', error);
        });
    },
    loadsubcategorias(id) {
    axios.get('https://membresiaintegra.com/php_api/subcategorias')
        .then(response => {
            // Guarda todas las subcategorías en el array subcategorias
            this.subcategorias = response.data;

            console.log("Subcategorías antes del filtrado:", this.subcategorias);

            // Filtra las subcategorías según el ID de la categoría seleccionada
            const filteredSubcategorias = this.subcategorias.filter(item => parseInt(item.id_categoria) === parseInt(id));

            console.log("Subcategorías después del filtrado:", filteredSubcategorias);

            // Guarda las subcategorías filtradas en una nueva propiedad
            this.filteredSubcategorias = filteredSubcategorias;
        })
        .catch(error => {
            console.error('Error al cargar subcategorías:', error);
        });
},
    // Otros métodos de tu componente...
    cargarImagen(evento) {
      const archivo = evento.target.files[0];
      if (archivo) {
        // Crear un objeto FileReader para leer el archivo
        const lector = new FileReader();
        lector.readAsDataURL(archivo);
        // Cuando la lectura esté completa, actualizar la URL de la imagen
        lector.onload = () => {
          this.imagenURL = lector.result;
          this.nueva_categoria.icono = this.imagenURL
          this.imagenCargada = true;
        };
      }
    },

    // Método para enviar datos al servidor
    enviarDatos() {
      const formData = new FormData();
      formData.append('nombre', this.nueva_categoria.nombre.toUpperCase());
      formData.append('facebook', this.nueva_categoria.facebook);
      formData.append('instagram', this.nueva_categoria.instagram);
      formData.append('website', this.nueva_categoria.website);
      formData.append('direccion', this.nueva_categoria.direccion.toUpperCase());
      formData.append('telefono', this.nueva_categoria.telefono);
      formData.append('icono', this.$refs.icono.files[0]);
      formData.append('imagen_url', " ");
      formData.append('id_categoria', this.selectedCategoria);
      formData.append('id_subcategoria', this.selectedSubcategoria);


      axios.post('https://membresiaintegra.com/php_api/empresas/nuevo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        console.log('Respuesta del servidor:', response.data);
        alert('Datos enviados con éxito.');
        this.closeModal();
        this.spinner = true;
        this.getClients()
      })
      .catch(error => {
        console.error('Error al enviar los datos:', error);
        alert('Ocurrió un error al enviar los datos. Por favor, inténtelo de nuevo.');
      });
    },

    searchChange() {
      if (this.searchInput == '') {
        this.getClients(this.sort);
      } else if (this.searchInput !== '' && this.selected_search == 'id') {
        this.search("id");    
      } else if (this.searchInput !== '' && this.selected_search == 'name') {
        this.search("name");       
      }
    },

    search(type) {
      const dataContent = clientController.search(type, this.searchInput);
      dataContent
        .then((response) => {
          //Succesfully result
          this.clients = response.data;
          console.log(this.clients);
        })
        .catch((err) => {
          //Error result
          console.error(err);
        });
    },

    userData() {
      const dataUser = userController.dataUser();
      dataUser
        .then((response) => {
          //Succesfully result
          this.user = response.data[0];
        })
        .catch((err) => {
          //Error result
          console.error(err);
        });
    },

    getClients() {
      const dataClients = clientController.dataEmpresas(this.sort);
      dataClients
        .then((response) => {
          //Succesfully result
          this.currentPage = 1;
          let clients = response.data;
          this.clients = clients;
          this.totalRows = clients.length;
          setTimeout(() => {
            this.spinner = 0;
          }, "1000");
        })
        .catch((err) => {
          //Error result
          console.error(err);
        });
    },
   
    changeSort(sort) {
      this.sort = sort;
      this.getClients();
    },

    edit(id) {
      const edit = this.clients.find((client) => client.id == id);
      this.editar_empresa.id = edit.id;
      this.editar_empresa.nombre = edit.nombre;
      this.editar_empresa.selectedCategoria = edit.id_categoria;
      this.editar_empresa.facebook = edit.facebook;
      this.editar_empresa.instagram = edit.instagram;
      this.editar_empresa.website = edit.website;
      this.editar_empresa.direccion = edit.direccion;
      this.editar_empresa.telefono = edit.telefono;
      this.loadsubcategorias(edit.id_categoria) 
      this.editar_empresa.selectedSubcategoria = edit.id_subcategoria;
      console.log(this.editar_empresa.selectedSubcategoria)
      this.$refs["edit-client-modal"].show();
    },

    deleteClient(id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!\nThe brands related to this client will be delete it too",
          icon: "warning",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            console.log(this.user.rol)
            let data = {
              id: id,
              rol: this.user.rol,
            };
            const deleteClient = clientController.deleteClient(data);
            deleteClient
              .then((response) => {
                //Succesfully result
                response;
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Your client has been deleted.",
                  "success"
                );
                this.resetData();
                this.spinner = true;
                this.getClients();
              })
              .catch((err) => {
                //Error result
                console.error(err);
              });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your client is safe",
              "error"
            );
          }
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    log(client) {
      const n = client.name.replace(/ /g, "-").toLowerCase();
      const link = `/client/${n}/brands/${client.id}`;
      this.$router.push(link);
    },

    addClient() {
      this.nueva_categoria.nombre = this.nueva_categoria.nombre.toUpperCase();
      this.enviarDatos(); // Llamar al método para enviar datos
      // Luego puedes agregar el resto de tu lógica si es necesario
    },

    saveClient() {
      this.editCLient.rol = this.user.rol;
      this.editCLient.name = this.editCLient.name.toUpperCase();
      const saveClient = clientController.update(this.editCLient);
      saveClient
        .then((response) => {
          //Succesfully result
          let data = response;
          this.alert(data, "success");
          this.closeModal();
          this.resetData();
          this.spinner = true;
          this.getClients();
        })
        .catch((err) => {
          this.alert(err, "danger"); //Error result
        });
    },

    alert(message, variant) {
      this.$bvToast.toast(`${message}`, {
        title: "CLIENT ALERT",
        variant: variant,
        solid: true,
      });
    },
    closeModal() {
      this.$refs["new-client-modal"].hide();
      this.$refs["edit-client-modal"].hide();
    },
    deleteCategory(id) {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger ml-2",
    },
    buttonsStyling: false,
  });

  swalWithBootstrapButtons
    .fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      confirmButtonText: "Sí, eliminarlo!",
      cancelButtonText: "No, cancelar!",
      showCancelButton: true,
    })
    .then((result) => {
      if (result.value) {
        axios.delete(`https://membresiaintegra.com/php_api/empresas/${id}/eliminar`)
          .then(() => {
            swalWithBootstrapButtons.fire(
              "¡Eliminado!",
              "Tu empresa ha sido eliminada.",
              "success"
            );
            this.resetData();
            this.spinner = true;
            this.getClients();
          })
          .catch((error) => {
            console.error(error);
            swalWithBootstrapButtons.fire(
              "Error",
              "Ocurrió un error al eliminar la empresa. Por favor, inténtelo de nuevo.",
              "error"
            );
          });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          "Cancelado",
          "Tu empresa está segura",
          "error"
        );
      }
    });
},
    resetData() {
      this.newCLient = {
        name: "",
        description: "",
        rol: "",
      };
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div  class="col-lg-6 text-lg-left">
          <h5 class="title-clients">Empresas<span class="dot-clients">.</span></h5>
      </div>
      <div class="button-div col-lg-6 text-lg-right">
      <button v-b-modal.client-popup-form class="button-new-client" style="">Agregar empresa</button>
      </div>
      
    </div>
    
    <!--START MODAL-->
    <b-modal
      ref="new-client-modal"
      id="client-popup-form"
      title="Nueva Empresa"
      hide-footer
      centered
      title-class="font-18"
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="name">Nombre empresa<span style="color:#247ba0">*</span></label>
            <input
              v-model="nueva_categoria.nombre"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <label for="categoria">Categoría<span style="color:#247ba0">*</span></label>
          <select v-model="selectedCategoria" class="form-control" id="categoria" @change="loadsubcategorias(selectedCategoria)">
              <option :value="null" disabled>Seleccionar categoría</option>
              <!-- Itera sobre las categorías para mostrarlas en el dropdown -->
              <option v-for="categoria in categorias" :key="categoria.id" :value="categoria.id">{{ categoria.nombre }}</option>
          </select>

          <!-- Dropdown para subcategorías -->
          <label style="padding-top: 10px;" for="subcategoria">Subcategoría<span style="color:#247ba0">*</span></label>
<select v-model="selectedSubcategoria" class="form-control" id="subcategoria">
    <option :value="null" disabled>Seleccionar subcategoría</option>
    <!-- Itera sobre las subcategorías filtradas para mostrarlas en el dropdown -->
    <option v-for="subcategoria in filteredSubcategorias" :key="subcategoria.id" :value="subcategoria.id">{{ subcategoria.nombre }}</option>
</select>

          
        </div>
        
        <div class="col-lg-12">
          <div class="form-group">
            <label style="padding-top:10px" for="logo_url">Subir logo<span style="color:#247ba0">*</span></label>
            <br> <input ref="icono" type="file" @change="cargarImagen" accept="image/*">
            <img width="50" height="50" :src="imagenURL" alt="Imagen cargada" v-if="imagenCargada">
          </div>
          <div class="form-group">
            <label for="name">Facebook<span style="color:#247ba0">*</span></label>
            <input
              v-model="nueva_categoria.facebook"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label for="name">Instagram<span style="color:#247ba0">*</span></label>
            <input
              v-model="nueva_categoria.instagram"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label for="name">Website<span style="color:#247ba0">*</span></label>
            <input
              v-model="nueva_categoria.website"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label for="name">Dirección<span style="color:#247ba0">*</span></label>
            <input
              v-model="nueva_categoria.direccion"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label for="name">Teléfono<span style="color:#247ba0">*</span></label>
            <input
              v-model="nueva_categoria.telefono"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="text-left">
            <button
              v-on:click="addClient()"
              class="btn button-client-form btn-primary"
            >
              Agregar 
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!--END MODAL-->

    <div v-if="spinner" style="text-align: center; padding-top: 150px">
      <b-spinner
        type="grow"
        class="mt-50"
        style="width: 3rem; height: 3rem; color:#011c27"
        role="status"
      ></b-spinner>
    </div>
    <div v-if="!spinner" class="card-clients fade-in">
      <div class="table-responsive div-table-clients">
     <table class="table mb-0 table-clients">
            <thead class="thead-table-clients">
                    <tr class="tr-table-clients">
                        <th width="2%" style="text-align: center;">ID</th>
                        <th>Logotipo</th>
                        <th>Nombre</th>
                        <th>Categoría</th>
                        <th>Subcategoría</th>
                        <th>Facebook</th>
                        <th>Instagram</th>
                        <th>Website</th>
                        <th>Dirección</th>
                        <th>Teléfono</th>
                        <th></th>
                        <th></th>
                    </tr>
            </thead>
            <tbody class="tbody-table-clients">
                    <tr v-for="(client, index) in clients" :key="index">
                      <td><span class="id-box-client">{{client.id}}</span></td>
                      <td><img width="50" height="50" :src="`${url_endpoint}/storage/app/public/iconos/empresa/${client.id}/${client.logo_url}`" alt="logo del cliente"></td>
                      <td><span>{{client.nombre}}</span></td>
                      <td><span>{{ getCategoriaNombre(client.id_categoria) }}</span></td>
                      <td><span>{{ getSubcategoriaNombre(client.id_subcategoria) }}</span></td>
                      <td><span>{{client.facebook}}</span></td>
                      <td><span>{{client.instagram}}</span></td>
                      <td><span>{{client.website}}</span></td>
                      <td><span>{{client.direccion}}</span></td>
                      <td><span>{{client.telefono}}</span></td>
                      <td><button v-on:click="edit(client.id)" class="btn button-brands-form btn-primary">EDITAR</button></td>   
                      <td><button @click="deleteCategory(client.id)" class="btn button-brands-form btn-danger">ELIMINAR</button></td>
                    </tr>
            </tbody>
      </table>
    </div>
    </div>
    

    <!--START MODAL2-->
    <b-modal
      ref="edit-client-modal"
      id="edit-client-popup-form"
      title="Editar empresa"
      hide-footer
      centered
      title-class="font-18"
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="name">Nombre Empresa<span style="color:#247ba0">*</span></label>
            <input
              v-model="editar_empresa.nombre"
              type="text"
              class="form-control"
              id="name"
              placeholder="Ingresa Nombre"
            />
          </div>
          <label for="categoria">Categoría<span style="color:#247ba0">*</span></label>
          <select v-model="editar_empresa.selectedCategoria" class="form-control" id="categoria" @change="loadsubcategorias(editar_empresa.selectedCategoria)">
              <option :value="null" disabled>Seleccionar categoría</option>
              <!-- Itera sobre las categorías para mostrarlas en el dropdown -->
              <option v-for="categoria in categorias" :key="categoria.id" :value="categoria.id">{{ categoria.nombre }}</option>
          </select>

          <!-- Dropdown para subcategorías -->
          <label style="padding-top: 10px;" for="subcategoria">Subcategoría<span style="color:#247ba0">*</span></label>
<select v-model="editar_empresa.selectedSubcategoria" class="form-control" id="subcategoria">
    <option :value="null" disabled>Seleccionar subcategoría</option>
    <!-- Itera sobre las subcategorías filtradas para mostrarlas en el dropdown -->
    <option v-for="subcategoria in filteredSubcategorias" :key="subcategoria.id" :value="subcategoria.id">{{ subcategoria.nombre }}</option>
</select>
        </div>
        <div style="padding-top: 10px;" class="col-lg-12">
          <div class="form-group">
            <label for="description">Subir icono<span style="color:#247ba0">*</span></label>
            <br><input ref="icono" type="file" @change="cargarImagen" accept="image/*">
          </div>
        </div>
      </div>
      <div class="form-group">
            <label for="name">Facebook<span style="color:#247ba0">*</span></label>
            <input
              v-model="editar_empresa.facebook"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label for="name">Instagram<span style="color:#247ba0">*</span></label>
            <input
              v-model="editar_empresa.instagram"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label for="name">Website<span style="color:#247ba0">*</span></label>
            <input
              v-model="editar_empresa.website"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label for="name">Dirección<span style="color:#247ba0">*</span></label>
            <input
              v-model="editar_empresa.direccion"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label for="name">Teléfono<span style="color:#247ba0">*</span></label>
            <input
              v-model="editar_empresa.telefono"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="text-right">
            <button
              v-on:click="editarDatos()"
              class="btn button-client-form btn-primary"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!--END MODAL2-->
  </Layout>
</template>
